<template>
  <div class="module-wrap" v-loading="loading" element-loading-text="加载中...">
    <el-button class="add-module" @click="dialogVisible = true">新增任务</el-button>
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="CreatedAt" label="创建时间"></el-table-column>
      <el-table-column prop="Status" label="状态">
        <template #default="scope">
          <span v-if="scope.row.Status === 'created'">排队中</span>
          <span v-if="scope.row.Status === 'pending'">处理中 ({{ processResult(scope.row.TaskResult) }})</span>
          <span v-if="scope.row.Status === 'completed'">已完成 ({{ processResult(scope.row.TaskResult) }})</span>
          <span v-if="scope.row.Status === 'failed'">任务失败 ({{ scope.row.TaskResult }})</span>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增任务" v-model="dialogVisible" width="40%" :before-close="handleClose">
      <el-form label-width="120px">
        <el-row>
          <el-form-item label="模板ID" style="width: 100%">
            <el-select v-model="addTaskData.templateId" style="width: 100%" placeholder="Select">
              <el-option v-for="item in templateData" :key="item.Title" :label="item.Title" :value="item.ID" />
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="手机号码" style="width: 100%">
            <el-input
              type="textarea"
              v-model="addTaskData.phones"
              :placeholder="tips"
              maxlength="13000000"
              rows="10"
              show-word-limit
            />
          </el-form-item>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Module',
  setup() {
    const pageSize = 10
    const total = ref(0)
    const tableData = ref([])
    const templateData = ref([])
    const page = ref(1)
    const title = ref('')
    const signId = ref('')
    const content = ref('')
    const applyReason = ref('')
    const dialogVisible = ref(false)
    const checkStatus = ref(0)
    const handleClose = () => (dialogVisible.value = false)
    const seeDialogVisible = ref(false)
    const loading = ref(false)
    const tips = ref('请提供待发送的手机号码，一行一个，如：\n13684561233\n15698874569')
    const addTaskData = ref({
      templateId: undefined,
      phones: ''
    })

    const onChangePage = val => {
      page.value = val
      getList()
    }

    const getList = async () => {
      const res = await axios.get('/-/user/sms/batch_task', {
        params: {
          size: pageSize,
          page: page.value
        }
      })

      const data = res.data.msg
      tableData.value = data
    }

    const getTemplateList = async () => {
      const res = await axios.get('/-/user/template/list', {
        params: {
          status: 'available',
          size: 999,
          page: 1
        }
      })

      templateData.value = res.data.msg.template
      if (templateData.value.length > 0) {
        addTaskData.value.templateId = templateData.value[0].ID
      }
    }

    const submit = async () => {
      try {
        const res = await axios.post('/-/user/sms/batch_task', {
          phone_str: addTaskData.value.phones,
          template_id: addTaskData.value.templateId
        })

        dialogVisible.value = false
        await getList()
        ElMessage('任务已创建')
      } catch (e) {
        switch (e.response.data.err_code) {
          case 0xe001001:
            ElMessage('请填写手机号码')
            break
          case 0xe001002:
            ElMessage('请充值余额，确保余额大于要发送的手机号码数量加1000')
            break
          case 0xe001003:
            ElMessage('当前仅支持无变量的短信发送')
            break
          default:
            ElMessage(JSON.stringify(e.response.data.err_msg))
        }
      }
    }

    const processResult = resStr => {
      let data = JSON.parse(resStr)

      if (data.Send === data.Count) {
        return `共发送 ${data.Count} 条`
      } else {
        return `需发送 ${data.Count} 条，已完成 ${Math.floor((data.Send / data.Count) * 100)}%`
      }
    }

    onMounted(async () => {
      loading.value = true
      try {
        await getList()
        await getTemplateList()
      } finally {
        loading.value = false
      }
    })

    return {
      tips,
      page,
      total,
      title,
      signId,
      submit,
      loading,
      content,
      pageSize,
      tableData,
      templateData,
      applyReason,
      handleClose,
      checkStatus,
      onChangePage,
      dialogVisible,
      seeDialogVisible,
      processResult,
      addTaskData
    }
  }
})
</script>
<style lang="less" scoped>
.module-wrap {
  position: relative;
  padding-top: 60px;
  background-color: #f2f2f2;

  .add-module {
    top: 10px;
    position: absolute;
    right: 20px;
  }

  .page {
    padding-top: 10px;
    text-align: right;
  }
}
</style>
